$breakpoint: '(min-width: #{map-get($breakpoints, md)}px)';

footer {
  margin-top: auto;
  padding: 1.5rem 0;
  background-color: $primary;
  color: $footer-color;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media #{$breakpoint} {
    background-image: url('/assets/footer-pattern.svg');
  }

  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media #{$breakpoint} {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .footer-item {
    margin: 0.75rem 0rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;

    @media #{$breakpoint} {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      padding: 4rem 0;
    }
  }

  .footer-link {
    text-decoration: none;
    color: $footer-color;
    letter-spacing: $footer-link-letter-spacing;

    &:hover {
      color: $footer-link-color-hover;
    }
  }

  .footer-spacer {
    margin: 0rem 1rem;
    display: none;

    @media #{$breakpoint} {
      display: inline-block;
    }
  }

  .footer-copyright {
    color: $footer-copyright-color;
  }

  .footer-logo {
    display: none;
    margin-left: 2rem;

    @media #{$breakpoint} {
      display: inline-block;
    }
  }
}
