form {
  max-width: 55rem;
}

fieldset {
  margin: 32px 0;
  padding: unset;
  border: unset;

  legend {
    padding: 0;
  }
}

.MuiFormControlLabel-label {
  margin: 16px 0;
}

.MuiFormGroup-root .MuiFormControlLabel-root {
  align-items: flex-start;

  .MuiCheckbox-root {
    padding-top: 4px;
  }
}
