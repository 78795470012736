@use 'sass:color';

button,
a {
  &.MuiButton-root {
    border-radius: $btn-border-radius;
    text-transform: unset;
    font-size: inherit;
    letter-spacing: 0.44px;
    line-height: 1rem;

    &.MuiButton-sizeSmall {
      padding: $btn-small-padding-y $btn-small-padding-x;
      font-weight: 400;
    }

    &.MuiButton-sizeMedium,
    &.MuiButton-sizeLarge {
      padding: $btn-medium-padding-y $btn-medium-padding-x;
    }

    &.MuiButton-outlinedSecondary {
      border-color: $secondary;
    }
  }

  &:hover {
    &.MuiButton-contained {
      background-color: color.adjust($secondary, $blackness: 8%);
    }
    &.MuiButton-outlined,
    &.MuiButton-text {
      background-color: rgba($secondary, 0.04);
    }
  }

  &:focus:not([disabled]) {
    &.MuiButton-contained {
      background-color: color.adjust($secondary, $blackness: 12%);
    }
    &.MuiButton-outlined,
    &.MuiButton-text {
      background-color: rgba($secondary, 0.12);
    }
  }

  &.MuiButton-contained {
    .MuiTouchRipple-root .MuiTouchRipple-ripple {
      color: color.adjust($secondary, $blackness: 10%);
    }
  }
  &.MuiButton-outlined,
  &.MuiButton-text {
    .MuiTouchRipple-root .MuiTouchRipple-ripple {
      color: rgba($secondary, 0.1);
    }
  }
}
