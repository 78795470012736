/* TYPOGRAPHY */

$font-family: NZPost Sans;
$font-weights: (
  'Light': 300,
  'Regular': 400,
  'Medium': 500,
  'Semibold': 600,
  'Bold': 700,
  'Ultra': 900
);
$font-styles: (
  'Italic': 'italic',
  'Normal': 'normal'
);

@each $weight-name, $weight in $font-weights {
  @each $style-name, $style in $font-styles {
    @font-face {
      font-family: $font-family;
      font-weight: $weight;
      font-style: $style;
      src: url('/fonts/NZPSANSWEB-#{$weight-name}-#{$style-name}.woff2') format('woff2');
    }
  }
}
