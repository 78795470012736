/* Generic Color */
$cobalt-blue: #041e42;
$avery-blue: #0033a0;
$dark-blue: #041e42;
$crimson: #df103a;
$black-pearl: #1a1e26;
$dark-grey: #424a50;
$ghosted: #7a8084;
$link-water: #c6c8ca;
$wild-sand: #ececec;
$white: #fff;
$dodger-blue: #0056f4;
$medium-sea-green: #008744;
$scarlet: #c7352e;
$turbo: #ed8100;

/* ====== Brand Setup  ======*/

/* Color */
$primary: $cobalt-blue;
$primary-text: $white;
$secondary: $avery-blue;
$secondary-text: $white;
$error: $scarlet;
$info: $dodger-blue;
$warning: $turbo;
$success: $medium-sea-green;

/* Buttons */
$btn-border-radius: 10px;
$btn-small-padding-y: 10px;
$btn-small-padding-x: 16px;
$btn-medium-padding-y: 16px;
$btn-medium-padding-x: 16px;

/* Footer */
$footer-link-letter-spacing: 0.1px;
$footer-color: rgba(255, 255, 255, 0.87);
$footer-link-color-hover: rgba(255, 255, 255, 0.76);
$footer-copyright-color: rgba(255, 255, 255, 0.62);

/* Font family */
$font-family: NZPost Sans, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
  Helvetica Neue;

/* Screen breakpoints */
$breakpoints: (
  xs: 0,
  sm: 600,
  md: 840,
  lg: 1024,
  xl: 1200 // etc.
);

/* Shadows (not all elevations are supported) */
$shadows: (
  0: '',
  1: (
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2)
  ),
  2: (
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2)
  ),
  3: (
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12),
    0 1px 8px 0 rgba(0, 0, 0, 0.2)
  ),
  4: (
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2)
  ),
  5: '',
  6: (
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2)
  ),
  7: '',
  8: (
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2)
  ),
  9: (
    0 9px 12px 1px rgba(0, 0, 0, 0.14),
    0 3px 16px 2px rgba(0, 0, 0, 0.12),
    0 5px 6px -3px rgba(0, 0, 0, 0.2)
  ),
  10: '',
  11: '',
  12: (
    0 12px 17px 2px rgba(0, 0, 0, 0.14),
    0 5px 22px 4px rgba(0, 0, 0, 0.12),
    0 7px 8px -4px rgba(0, 0, 0, 0.2)
  ),
  13: '',
  14: '',
  15: '',
  16: (
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2)
  ),
  17: '',
  18: '',
  19: '',
  20: '',
  21: '',
  22: '',
  23: '',
  24: (
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px rgba(0, 0, 0, 0.2)
  )
);
