.banner.MuiAlert-root {
  padding: 24px;

  .MuiAlert-icon {
    margin-right: 16px;
    padding: 8px;
    background-color: $secondary;
    border-radius: 50%;
    height: 100%;
    color: $secondary-text;
  }

  .MuiAlertTitle-root {
    color: $primary;
    font-size: 1.25rem;
  }

  .MuiAlert-message {
    color: rgba(28, 32, 35, 0.87);
    font-size: 1rem;
    padding-top: 0;
    letter-spacing: 0.44px;

    @media (min-width: #{map-get($breakpoints, lg)}px) {
      display: flex;
    }
  }

  .banner-body {
    display: flex;
    flex-direction: column;
  }

  .banner-actions {
    display: flex;
    padding-left: 16px;
    margin-top: auto;
    justify-content: flex-end;

    .MuiButton-root {
      width: max-content;
    }
  }

  &.MuiAlert-outlined {
    border: 0;
  }
}
