/* GENERAL STYLES
-------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6,
legend {
  color: $dark-blue;
}

h1 {
  font-size: 4.25rem;
  font-weight: 700;
  line-height: 5.5rem;
  letter-spacing: -1.06px;
  margin-bottom: 20px;
}

h2 {
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 4rem;
  letter-spacing: -0.43px;
  margin-bottom: 12px;
}

h3 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3rem;
  letter-spacing: 0px;
  margin-bottom: 8px;
}

h4 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2.5rem;
  letter-spacing: 0.24px;
  margin-bottom: 8px;
}

h5 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0px;
  margin-bottom: 4px;
}

h6 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.15px;
  margin-bottom: 4px;
}

legend {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.15px;
  margin-bottom: 16px;
}

a {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.44px;
}

button {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.44px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'NZPost Sans', 'Segoe UI', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  margin-bottom: 48px;
  @media (min-width: #{map-get($breakpoints, md)}px) {
    margin-bottom: 60px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
