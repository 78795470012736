.MuiSnackbar-root {
  &[class*='MuiSnackbar-anchorOriginBottom'] {
    position: sticky;
    margin-bottom: 24px;
  }

  &[class*='Center'] {
    margin-left: auto;
    margin-right: auto;
    transform: unset;
  }
}
