header {
  height: 60px;
  justify-content: center;

  button,
  a {
    &.MuiButton-root {
      font-weight: 600;
      color: #fff;
    }
  }
}
